import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, withTheme } from '@material-ui/core/styles'
import { isMobile } from "../isMobile"
import {
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  FormControlLabel,
} from '@material-ui/core'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/Home'
import ListIcon from '@material-ui/icons/List'
import CodeIcon from '@material-ui/icons/Code'
import SchoolTwoTone from '@material-ui/icons/SchoolTwoTone'
import PublishIcon from '@material-ui/icons/PublishTwoTone'
import MoneyIcon from '@material-ui/icons/AttachMoneyTwoTone'
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNewTwoTone'
import PolymerIcon from '@material-ui/icons/PolymerTwoTone'
import StarsIcon from '@material-ui/icons/Stars'
import AccessTimeIcon from '@material-ui/icons/AccessTimeTwoTone'
import LockOpenIcon from '@material-ui/icons/LockOpenTwoTone'
import Collections from '@material-ui/icons/CollectionsTwoTone'
import ShoppingCart from '@material-ui/icons/ShoppingCartTwoTone'
import Watch from '@material-ui/icons/WatchTwoTone'
import ReportProblemIcon from '@material-ui/icons/ReportProblemTwoTone'
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet'

import clsx from 'clsx'
import { Link, navigate } from 'gatsby'

import Logo from './logo'
import Footer from './footer'

const drawerWidth = 240

const styles = theme => ({
  grow: {
    flexGrow: 1
  },
  logo: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  container: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  menuTitle: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    color: theme.palette.primary.light,
    fontWeight: 'bold',
  },
  switch: {
    margin: theme.spacing(1),
  },
  // Loads information about the app bar, including app bar height
  toolbar: theme.mixins.toolbar,
})

class MenuItem extends React.Component {
  render() {
    return (
      <ListItem button onClick={() => navigate(this.props.url)}>
        <ListItemIcon>
          {this.props.icon}
        </ListItemIcon>
        <ListItemText>
          {this.props.name}
        </ListItemText>
      </ListItem>
    )
  }
}

MenuItem.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
}

@withTheme
@withStyles(styles)
class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: true, // SSR mobile first
      open: false,
    }
  }

  componentDidMount() {
    if (process.browser) {
      // ssr is mobile, so if we are in desktop => rerender
      if (this.state.isMobile !== isMobile()) {
        this.setState({ isMobile: isMobile(), open: !isMobile() })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // this code listens for a mutation on the main-wrapper element and resets
    // the style attribute back to "null".
    // This is necessary because Adsense injects style into this main-wrapper
    // element changing its height properties.
    // ie : <div id="root" style="height: auto !important;">
    const isSSR = typeof window === "undefined"
    if (!isSSR) {
      const rootContainer = document.getElementById('docs-root-container')
      rootContainer.style.height = ''
      rootContainer.style.minHeight = ''
    }
    // end of adsense hack
  }

  handleDrawerOpen = () => {
    this.setState({ open: true })
  }

  handleDrawerClose = () => {
    if (isMobile()) {
      this.setState({ open: false, isMobile: isMobile() })
    }
  }

  render() {
    // avoid flickering effect on desktop
    if (this.state.isMobile !== isMobile()) {
      return null
    }
    const { classes, theme } = this.props
    return (
      <div id="docs-root-container" className={classes.container}>
        <AppBar position="fixed" elevation={0} className={clsx(classes.appBar, { [classes.appBarShift]: (this.state.isMobile ? this.state.open : false) })}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={this.handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, (this.state.isMobile ? (this.state.open && classes.hide) : classes.hide))}
            >
              <MenuIcon />
            </IconButton>
            <Link to="/" className={classes.logo} style={{ textDecoration: 'none' }}>
              <Logo color={theme.palette.secondary.main} />
            </Link>
            <Link to="/" style={{ color: "white", textDecoration: 'none' }}>
              <Typography variant="h6" color="inherit" noWrap>
                Fit
              </Typography>
            </Link>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <Typography variant="h6" color="secondary" noWrap>
                Face
              </Typography>
            </Link>
            <div className={classes.grow} />
            <a href="https://fitface.xyz" style={{ color: theme.palette.secondary.main, textDecoration: 'none' }}>
              <Typography color="inherit" noWrap>
                {this.state.isMobile ? 'Go' : 'Return'} to FitFace.xyz
              </Typography>
            </a>
          </Toolbar>
        </AppBar>
        <div className={classes.toolbar} />
        <Drawer
          className={classes.drawer}
          variant={this.state.isMobile ? "persistent" : "permanent"}
          anchor="left"
          open={this.state.open}
          onClick={this.handleDrawerClose}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <MenuItem url="/" icon={<HomeIcon />} name="Home" />
            <Divider />
            <Typography align="left" className={classes.menuTitle}>FOR USERS</Typography>
            <Divider />
            <MenuItem url="/user/getstarted" icon={<PowerSettingsNew />} name="Get Started" />
            <MenuItem url="/user/collections" icon={<Collections />} name="Install a design" />
            <MenuItem url="/user/buydesign" icon={<ShoppingCart />} name="Buy a design" />
            <MenuItem url="/user/demo" icon={<Watch />} name="Try a demo" />
            <Divider />
            <MenuItem url="/user/unlock" icon={<LockOpenIcon />} name="Unlock" />
            <Divider />
            <Typography align="left" className={classes.menuTitle}>FOR DESIGNERS</Typography>
            <Divider />
            <MenuItem url="/designer/editor" icon={<AccessTimeIcon />} name="The Editor" />
            <MenuItem url="/designer/elements" icon={<ListIcon />} name="Elements" />
            <MenuItem url="/designer/tags" icon={<CodeIcon />} name="Tags" />
            <MenuItem url="/designer/expressions" icon={<PolymerIcon />} name="Expressions" />
            <MenuItem url="/designer/tutorials" icon={<SchoolTwoTone />} name="Tutorials" />
            <MenuItem url="/designer/publish" icon={<PublishIcon />} name="Publish" />
            <MenuItem url="/designer/money" icon={<MoneyIcon />} name="Get Paid" />
            <MenuItem url="/designer/api" icon={<SettingsEthernetIcon />} name="API" />
            <MenuItem url="/designer/premium" icon={<StarsIcon />} name="Premium" />
            <MenuItem url="/designer/limitations" icon={<ReportProblemIcon />} name="Limitations" />
            <Divider />
            <FormControlLabel
              className={classes.switch}
              control={
                <Switch
                  checked={(theme.palette.type !== 'dark')}
                  onChange={this.props.toggleTheme}
                  color="secondary"
                />
              }
              label="Dark/Light Theme"
            />
          </List>
        </Drawer>
        <main className={clsx(classes.content, { [classes.contentShift]: !this.state.isMobile })}>
          {this.props.children}
        </main>
        <div className={clsx({ [classes.contentShift]: !this.state.isMobile })}>
          <Footer />
        </div>
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  toggleTheme: PropTypes.func.isRequired,
}

export default withTheme(withStyles(styles)(Layout))
