import { isMobile as __isMobile } from "react-device-detect"

export function isMobile() {
  if (process.browser) {
    return __isMobile
  } else {
    // SSR mobile first
    return true
  }
}
