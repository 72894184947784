import React, { Fragment } from 'react'
import { isMobile } from "../isMobile"
import { withStyles, withTheme } from '@material-ui/core/styles'
import Reaptcha from 'reaptcha'

import {
  Box,
  Paper,
  Grid,
  Button,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  Fab,
  IconButton,
  Icon,
  Popover,
  Divider,
  Snackbar,
} from '@material-ui/core'

const styles = theme => ({
  fab: {
    position: 'fixed',
    right: "10px",
    bottom: "6px",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    }
  },
  paper: {
    maxWidth: 350,
  },
  contact: {
    padding: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  contactIcon: {
    color: theme.palette.common.white,
    float: 'right',
    position: 'absolute',
    right: '2px',
    top: '2px',
  },
  form: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(1),
  },
  captcha: {
    minHeight: isMobile() ? '150px' : '74px',
  }
})

@withTheme
@withStyles(styles)
class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.captcha = null
    this.state = {
      anchorEl: null,
      open: false,
      snackOpen: false,
      captcha: false,
      gRecaptchaResponse: "",
      errors: {},
      email: '',
      subject: '',
      message: '',
    }
  }

  sendEmail = () => {
    fetch(process.env.GATSBY_CONTACT_API_URL, {
      mode: 'cors',
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: this.state.email,
        subject: this.state.subject,
        message: this.state.message,
        captcha: this.state.gRecaptchaResponse,
      })
    }).then((response) => {
      if (!response.ok) {
        throw response
      }
      this.captcha.reset()
      this.setState({
        snackOpen: true,
        open: false,
        anchorEl: null,
        captcha: false,
        gRecaptchaResponse: "",
        errors: {},
        email: '',
        subject: '',
        message: '',
      })
    }).catch((error) => {
      error.json().then(json => {
        this.setState({ errors: json.errors })
      })
    })
  }

  handleCaptcha = (key) => {
    this.setState({
      captcha: true,
      gRecaptchaResponse: key
    })
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }

  renderFieldErrors(errors) {
    if (errors) {
      const errs = errors.map((error, index) => {
        return (<li key={index}>{error}</li>)
      })
      return (<FormHelperText error>{errs}</FormHelperText>)
    } else {
      return null
    }
  }

  render() {
    const { classes, theme } = this.props
    const disabled = (this.state.email.length === 0) || (this.state.subject.length === 0) || (this.state.message.length === 0) || (this.state.captcha === false)
    const captchaError = (this.state.errors.captcha !== undefined)
    return (
      <Fragment>
        <Popover
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Paper className={classes.paper}>
            <div className={classes.contact}>
              <Typography variant="h5" align="center" color="inherit">Contact us</Typography>
              <IconButton className={classes.contactIcon} onClick={() => this.setState({ open: false, anchorEl: null })}>
                <Icon>close</Icon>
              </IconButton>
            </div>
            <Divider />
            <Box className={classes.form}>
              <FormControl fullWidth autoFocus>
                <TextField
                  error={this.state.errors.email}
                  label="Your email"
                  type="email"
                  value={this.state.email}
                  onChange={this.handleChange('email')}
                  margin="normal"
                />
                {this.renderFieldErrors(this.state.errors.email)}
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  error={this.state.errors.subject}
                  label="Subject"
                  value={this.state.subject}
                  onChange={this.handleChange('subject')}
                  margin="normal"
                />
                {this.renderFieldErrors(this.state.errors.subject)}
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  error={this.state.errors.message}
                  label="Message"
                  multiline
                  rows="4"
                  rowsMax="4"
                  value={this.state.message}
                  onChange={this.handleChange('message')}
                  margin="normal"
                />
                {this.renderFieldErrors(this.state.errors.message)}
              </FormControl>
              <FormControl error={captchaError} fullWidth>
                <Reaptcha
                  className={classes.captcha}
                  ref={e => (this.captcha = e)}
                  sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                  onVerify={this.handleCaptcha}
                  theme={theme.palette.type}
                  size={isMobile() ? 'compact' : 'normal'}
                />
                {this.renderFieldErrors(this.state.errors.captcha)}
              </FormControl>
              <Grid container direction="row" justify="space-around" align="center">
                <Button color="inherit" variant="outlined" onClick={() => this.setState({ open: false, anchorEl: null, email: '', subject: '', message: '', errors: {} })}>
                  Cancel
                </Button>
                <Button color="primary" variant="contained" onClick={this.sendEmail} disabled={disabled}>
                  <Icon style={{ marginRight: "10px" }}>send</Icon>
                  Send
                </Button>
              </Grid>
            </Box>
          </Paper>
        </Popover>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.snackOpen}
          autoHideDuration={2000}
          onClose={() => this.setState({ snackOpen: false })}
          message={<span id="message-id">Message Sent</span>}
          action={[
            <Button key="undo" color="secondary" size="small" onClick={() => this.setState({ snackOpen: false })}>
              CLOSE
            </Button>,
            <IconButton key="too" color="inherit" className={classes.close} onClick={() => this.setState({ snackOpen: false })}>
              <Icon>close</Icon>
            </IconButton>,
          ]}
        />
        <Fab size="small" className={classes.fab} onClick={(e) => this.setState({ open: true, anchorEl: e.currentTarget })}>
          <Icon>help_outline</Icon>
        </Fab>
      </Fragment>
    )
  }
}

export default withTheme(withStyles(styles)(Contact))
