import React from "react"
import PropTypes from 'prop-types'

export default function Logo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 190 190"
      height={props.height}
      width={props.width}
    >
      <g transform="translate(-24.270622,-39.792091)" id="layer1">
        <g stroke={props.color} transform="translate(4.9999976,-8.1209194)" id="g4576">
          <path
            fill="none" stroke={props.color} strokeWidth="11" strokeLinecap="round" strokeLinejoin="round"
            id="path815"
            d="M 190.9995,134.09225 A 76.729164,76.729164 0 0 1 114.28272,210.82142 76.729164,76.729164 0 0 1 37.541172,134.11703 76.729164,76.729164 0 0 1 114.23317,57.3631 76.729164,76.729164 0 0 1 190.99948,134.0427"
          />
          <path
            fill="none" stroke={props.color} strokeWidth="9" strokeLinecap="round" strokeLinejoin="round"
            id="path815-3"
            d="M 175.22787,206.28335 A 91.458336,91.458336 0 0 1 53.313243,206.12748"
          />
          <path
            fill="none" stroke={props.color} strokeWidth="11" strokeLinecap="round" strokeLinejoin="round"
            d="m 114.27062,77.509217 0.0668,62.590363"
            id="path4539"
          />
          <path
            fill="none" stroke={props.color} strokeWidth="11" strokeLinecap="round" strokeLinejoin="round"
            d="m 152.28735,140.10758 -37.94993,-0.008"
            id="path4541"
          />
        </g>
      </g>
    </svg>
  )
}

Logo.propTypes = {
  color: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
}

Logo.defaultProps = {
  height: "28",
  width: "28",
}
