import React, { Fragment } from 'react'
import { isMobile } from "../isMobile"
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import {
  Typography,
} from '@material-ui/core'

import Layout from './layout'
import Seo from './seo'
import Contact from './contact'

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  pageContainer: {
    margin: isMobile() ? 0 : '0 15%',
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
  },
  ads: {
    width: '100%',
    height: '100px',
    maxHeight: '120px',
    marginTop: theme.spacing(1),
    // backgroundColor: "#f00"
  },
})

class GoogleAds extends React.Component {
  componentDidMount() {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({})
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    return (
      <ins
        className='adsbygoogle'
        style={{ display: "block" }}
        data-ad-client="ca-pub-9273139492598056"
        data-ad-slot="6959142903"
        data-ad-format="horizontal"
        data-full-width-responsive="true"
      />
    )
  }
}

@withStyles(styles)
class Page extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes, title, seo } = this.props
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title,
                description,
              }
            }
          }
        `}
        render={data => {
          return (
            <Fragment>
              <Seo title={seo || title} description={data.site.siteMetadata.description} />
              <Layout toggleTheme={this.props.toggleTheme}>
                {title && <Typography variant="h3" align="center" color="secondary" className={classes.pageTitle}>{title}</Typography>}
                <div className={classes.pageContainer}>
                  {this.props.children}
                  <div key={data.site.siteMetadata.title} className={classes.ads}>
                    <GoogleAds />
                  </div>
                </div>
                <Contact />
              </Layout>
            </Fragment>
          )
        }}
      />
    )
  }
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  toggleTheme: PropTypes.func.isRequired,
}

export default withStyles(styles)(Page)
