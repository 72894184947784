import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'gatsby'

import {
  Grid,
  Typography,
  Button,
  Divider,
} from '@material-ui/core'

const styles = theme => ({
  section: {
    padding: "10px 0",
    backgroundColor: theme.palette.primary.dark,
    borderTop: '1px solid ' + theme.palette.primary.dark,
  },
  container: {
    color: theme.palette.grey['500'],
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  copyright: {
    color: theme.palette.grey['500'],
    margin: theme.spacing(1),
  },
  item: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.grey['500'],
  }
})

@withStyles(styles)
class Footer extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <section id="footer" className={classes.section}>
        <Grid className={classes.container} direction="row" container justify="center" alignItems="center">
          <Link className={classes.link} to="/agreements/terms">
            <Button size="small" color="inherit">Terms</Button>
          </Link>
          <Link className={classes.link} to="/agreements/legal">
            <Button size="small" color="inherit">Legal</Button>
          </Link>
          <a className={classes.link} href="https://privacypolicies.com/privacy/view/c414516349cc2105a32db9afedc86288" rel="noopener noreferrer" target="_blank">
            <Button size="small" color="inherit">Privacy</Button>
          </a>
          <Link className={classes.link} to="/agreements/refund">
            <Button size="small" color="inherit">Refund</Button>
          </Link>
        </Grid>
        <Divider />
        <div className={classes.copyright}>
          <Typography variant="body2" align="center" color="inherit">
            Copyright © 2019 All Rights Reserved
          </Typography>
          <Typography variant="body2" align="center" color="inherit">
            SystemD International LP - Company number SL034277 - 5 South Charlotte Street, Edinburgh EH2 4AN, Scotland, United Kingdom
          </Typography>
        </div>
      </section>
    )
  }
}

export default withStyles(styles)(Footer)
