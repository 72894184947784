import React from 'react'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import JssProvider from 'react-jss/lib/JssProvider'
import getPageContext from './getPageContext'

const palette0 = {
  main: "#33527d",
  dark: "#002a50",
  light: "#627eac",

  50: '#cedaeb',
  100: '#98b1d5',
  200: '#7093c5',
  300: '#456ea8',
  400: '#3c6093',
  500: '#33527d',
  600: '#2a4467',
  700: '#213551',
  800: '#18273c',
  900: '#101926',
  A100: '#bbd3f4',
  A200: '#639ae6',
  A400: '#3167b2',
  A700: '#355d94',
  contrastDefaultColor: 'light',
}

const palette1 = {
  main: "#ff8903",
  dark: "#c55a00",
  light: "#ffba46",
  contrastDefaultColor: 'dark',
}

const baseTheme = {
  palette: {
    type: 'dark',
    primary: palette0,
    secondary: palette1,
  },
  typography: { useNextVariants: true },
  overrides: {
    MuiButton: {
      textPrimary: {
        color: palette0.light,
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: palette0.light,
      },
    },
  }
}

function withRoot(Component) {
  class WithRoot extends React.Component {
    constructor(props) {
      super(props)
      this.muiPageContext = getPageContext()
      this.state = {
        type: (process.browser ? (window.localStorage.getItem("theme") || 'dark') : 'dark')
      }
    }

    componentDidUpdate() {
      if (process.browser) {
        const theme = window.localStorage.getItem("theme") || 'dark'
        if (this.state.type !== theme) {
          this.setState({ type: theme })
        }
      }
    }

    componentDidMount() {
      // Remove the server-side injected CSS.
      const jssStyles = document.querySelector('#jss-server-side')
      if (jssStyles && jssStyles.parentNode) {
        jssStyles.parentNode.removeChild(jssStyles)
      }
    }

    toggleTheme = () => {
      const newPaletteType = this.state.type === "light" ? "dark" : "light"
      this.setState({ type: newPaletteType }, function() {
        if (process.browser) {
          window.localStorage.setItem('theme', newPaletteType)
        }
      })
    }

    render() {
      const theme = { ...baseTheme, palette: { ...baseTheme.palette, type: this.state.type } }
      return (
        <JssProvider generateClassName={this.muiPageContext.generateClassName}>
          {/* Make the theme available down the React tree. */}
          <MuiThemeProvider theme={createMuiTheme(theme)}>
            {/* Kickstart a simple baseline to build upon. */}
            <CssBaseline />
            <Component {...this.props} toggleTheme={this.toggleTheme} />
          </MuiThemeProvider>
        </JssProvider>
      )
    }
  }

  return WithRoot
}

export default withRoot
